@import "core";
.page-checkout-summary {
	background-color: $color-bg;
		
	.checkout-summary-wrap {
		padding: 2.5rem 0 2.7rem;
	}

	.checkout-summary {
		margin: 0 auto;
		padding: 2rem;
		border-radius: $radius-general;
		background-color: $color-white;
		
		@include mediaMin {
			width: 78rem;
		}
	}

		.summary-title {
			@include font-bold;
			color: $color-primary;
			font-size: 3.2rem;
			padding-bottom: 1.5rem;
			border-bottom: .1rem solid $color-border;
			margin-bottom: 1.4rem;
		}

			.title-icon {
				display: block;
				font-size: 3rem;
				margin-bottom: .9rem;
			}

		.summary-info {
			background-color: $color-yellow-light;
			padding: 1.6rem 4rem 1.6rem 1rem;
			font-size: 1.4rem;
			margin-top: 1.5rem;
			border-radius: $radius-general;
			display: flex;
			align-items: flex-start;

			@include media {
				padding-right: 1rem;
			}

			.icon {
				font-size: 2.3rem;
				margin-right: 1.1rem;
			}
		}

		.summary-event {
			padding: 2rem 0;
			border-top: .1rem solid $color-border;
			border-bottom: .1rem solid $color-border;
			display: flex;
			margin-top: 2rem;
		}

			.event-image {
				width: 14.1rem;
				height: 18.1rem;
				border-radius: $radius-general;
				margin-right: 1.7rem;

				@include media {
					width: 9rem;
					height: 12.1rem;
				}
			}
			
			.event-text {
				flex: 1 0 0;
			}

			.event-title {
				font-size: 3.2rem;
				display: block;

				@include media {
					font-size: 2rem;
					padding-bottom: 1rem;
				}
			}

			.event-infoline {
				@include font-medium;
				margin-top: 1.5rem;

				@include media {
					margin-top: .5rem;
					font-size: 1.2rem;
				}

				.icon {
					font-size: 1.6rem;
					margin-right: 1.2rem;

					@include media {
						font-size: 1.4rem;
						margin-right: .8rem;
					}
				}
			}

		.summary-tickets {
			margin-top: 2rem;
		}

			.tickets-title {
				display: block;
				font-size: 1.6rem;
			}

			.tickets-ticket {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 1.4rem;
			}

		.summary-prices {
			padding: 2rem;
			background-color: $color-bg-light;
			border-radius: $radius-general;
			margin-top: 2.1rem;
		}

			.prices-item {
				display: flex;
				align-items: center;
				justify-content: space-between;

				+ .prices-item {
					margin-top: 1rem;
				}

				&.total {
					@include font-bold;
					border-top: .1rem solid $color-border;
					padding-top: 1.2rem;
					font-size: 1.6rem;
					margin-top: 2rem;
				}
			}

		.summary-controls {
			margin-top: 2rem;

			> * {
				margin-right: 1rem;

				&:last-child {
					margin-right: 0;
				}
			}
		}
}